import { USER_ROLES } from "./constants";
import {
  isAPullOrPushClientLoggedIn,
  isASupplierUserLoggedIn,
  isAPullClientLoggedIn,
  isAPushClientLoggedIn
} from "./utils/secure-xs/secureSS";

const {
  SUPER_USER,
  USER,
  POTENTIAL_USER,
  CLIENT_ADMIN,
  CLIENT_SUPPORT,
  CLIENT_FINANCE,
  CLIENT_COMMERCIAL_TEAM,
  CLIENT_DEV,
  CLIENT_EXTERNAL_DEV,
  PROPERTY_ADMIN,
  PROPERTY_FINANCE,
  PROPERTY_ANALYST,
  PROPERTY_SUPPORT,
  RESERVATION_AGENT
} = USER_ROLES;
const rules = {
  [USER]: {
    static: [],
    homePage: "/home"
  },
  [SUPER_USER]: {
    static: [
      "dayrateConfig",
      "advancedARISearch",
      "quickPriceSearch",
      "searchApiRates",
      "dayRateCalendarLookup",
      "dayRateCalendar",
      "rateAvailabilityAudit",
      "propertyManagement",
      "policies",
      "hotelbedsMapping",
      "rooms",
      "reports",
      "admin-reports",
      "suOnlyControls",
      "validateReservations",
      "updateGuestInformation",
      "cacheLiveStatus",
      "usersCompaniesManagement",
      "cancelReservation",
      "rateplanManagement",
      "rateplansListManagement",
      "reportsFailed",
      "offlineExcelExport",
      "directBillUpload",
      "relocatedResExport",
      "vccTransaction",
      "invoiceModule",
      "invoiceModuleSuperAdmin",
      "IntgARIMonitoringManagement",
      "auditARIDashboard",
      "clientManagement",
      "listClients",
      "addClient",
      "stratificationManagement",
      "omenQuickPriceSearch"
    ],
    homePage: "/home"
  },
  [POTENTIAL_USER]: {
    static: ["dayrateConfig", "advancedARISearch", "quickPriceSearch"],
    homePage: "/home/advanced-ari-search"
  },
  [CLIENT_ADMIN]: {
    static: [
      "dayrateConfig",
      "advancedARISearch",
      "reports",
      "invoiceModule",
      "invoiceModuleClientAdmin",
      "clientManagement",
      "viewClientInfo"
    ],
    dynamic: {
      "reports:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      dayrateConfig: () => isAPullOrPushClientLoggedIn(),
      advancedARISearch: () => isAPullOrPushClientLoggedIn(),
      quickPriceSearch: () => isAPullClientLoggedIn(),
      dayRateCalendarLookup: () => isAPushClientLoggedIn()
      // exportTgxClientMetadata: () => isAPullClientLoggedIn()
    },
    homePage: "/home/reservation-report"
  },
  [CLIENT_FINANCE]: {
    static: [
      "reports",
      "invoiceModule",
      "invoiceModuleClientAdmin",
      "clientManagement",
      "viewClientInfo"
    ],
    dynamic: {
      "reports:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      }
    },
    homePage: "/home/invoices/list"
  },
  [CLIENT_SUPPORT]: {
    static: ["reports", "dayrateConfig", "advancedARISearch"],
    dynamic: {
      dayrateConfig: () => isAPullOrPushClientLoggedIn(),
      advancedARISearch: () => isAPullOrPushClientLoggedIn(),
      quickPriceSearch: () => isAPullClientLoggedIn(),
      dayRateCalendarLookup: () => isAPushClientLoggedIn()
      // exportTgxClientMetadata: () => isAPullClientLoggedIn()
    },
    homePage: "/home/reservation-report"
  },
  [CLIENT_COMMERCIAL_TEAM]: {
    static: [
      "dayrateConfig",
      "advancedARISearch",
      "reports",
      "invoiceModule",
      "invoiceModuleClientAdmin",
      "clientManagement",
      "viewClientInfo"
    ],
    dynamic: {
      "reports:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
      dayrateConfig: () => isAPullOrPushClientLoggedIn(),
      advancedARISearch: () => isAPullOrPushClientLoggedIn(),
      quickPriceSearch: () => isAPullClientLoggedIn(),
      dayRateCalendarLookup: () => isAPushClientLoggedIn()
    },
    homePage: "/home/reservation-report"
  },
  [CLIENT_DEV]: {},
  [CLIENT_EXTERNAL_DEV]: {},
  [PROPERTY_ADMIN]: {
    static: [
      "reports",
      "offlineExcelExport",
      "dayrateConfig",
      "viewDownloadUploadARI",
      "advancedARISearch",
      "quickPriceSearch",
      "dayRateCalendarLookup",
      "openCloseAvailability"
    ],
    dynamic: {
      "reports:edit": ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      }
    },
    homePage: "/home/view-download-upload-ari"
  },
  [PROPERTY_FINANCE]: {
    static: ["dayrateConfig", "viewDownloadUploadARI", "reports"],
    dynamic: {
      reports: isASupplierUserLoggedIn()
    },
    homePage: "/home/view-download-upload-ari"
  },
  [PROPERTY_ANALYST]: {
    static: ["dayrateConfig", "viewDownloadUploadARI", "openCloseAvailability"],
    homePage: "/home/view-download-upload-ari"
  },
  [PROPERTY_SUPPORT]: {
    static: ["dayrateConfig", "advancedARISearch", "quickPriceSearch", "reports"],
    homePage: "/home/reservation-report"
  },
  [RESERVATION_AGENT]: {
    static: [
      "reports",
      "offlineExcelExport",
      "dayrateConfig",
      "advancedARISearch",
      "quickPriceSearch",
      "dayRateCalendarLookup"
    ],
    homePage: "/home/advanced-ari-search"
  }
};

export default rules;
